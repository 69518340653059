<template>
  <div class="setting-page">
    <h2>配置信息</h2>
    <div class="setting-container">
      <el-tabs
        v-model="tabs"
        type="card"
      >
        <el-tab-pane v-if="powerlimits.includes('pzxx_other')" label="其他" name="pzxx_other">
          <div class="pane-content">
            <el-form
              class="form"
              :model="otherForm"
              :rules="otherFormRules" label-width="120" label-suffix="：">
              <el-form-item label="联系人电话" prop="mobile">
                <el-input
                  v-model="otherForm.mobile"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item>
                <el-button
                  v-if="powerlimits.includes('pzxx_bc')"
                  class="submit-btn"
                  type="primary"
                  :loading="otherLoading"
                  @click="storeOther"
                >保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="powerlimits.includes('pzxx_qywx')" label="企业微信" name="pzxx_qywx">
          <div class="pane-content">
            <el-form
              class="form"
              label-width="120"
              :model="enterpriseWeChat"
              label-suffix="："
            >
              <el-form-item label="企业名称" prop="corp_name">
                <el-input v-model="enterpriseWeChat.corp_name" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="企业ID" prop="corpid">
                <el-input v-model="enterpriseWeChat.corpid" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="应用Secret" prop="yy_secret">
                <el-input v-model="enterpriseWeChat.yy_secret" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="应用token" prop="yy_token">
                <el-input v-model="enterpriseWeChat.yy_token" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="企业token" prop="all_token">
                <el-input v-model="enterpriseWeChat.all_token" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="企业Secret" prop="all_secret">
                <el-input v-model="enterpriseWeChat.all_secret" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="企业秘钥" prop="encoding_aes_key">
                <el-input v-model="enterpriseWeChat.encoding_aes_key" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="应用秘钥" prop="yy_encoding_aes_key">
                <el-input v-model="enterpriseWeChat.yy_encoding_aes_key" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="应用id" prop="agentid">
                <el-input v-model="enterpriseWeChat.agentid" placeholder="请输入" />
              </el-form-item>
              <el-form-item
                label="二维码"
                prop="img"
                :rules="{required: true, message: '请上传二维码'}"
              >
                <div class="upload">
                  <aw-upload
                    :mode="uploadMode"
                    @action="uploadAction"
                  >
                    <template #button>
                      <div class="upload-box">
                        <template v-if="!enterpriseWeChat.img">
                          <el-icon><Plus /></el-icon>
                          <span class="text">上传</span>
                        </template>
                        <el-image
                          :src="enterpriseWeChat.img"
                        />
                      </div>
                    </template>
                  </aw-upload>
                  <p class="tips-text">建议尺寸：最大上传5张，建议200*200px像素，png、jpg、jpeg格式</p>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button
                  v-if="powerlimits.includes('pzxx_bc')"
                  class="submit-btn"
                  type="primary"
                  :loading="enterpriseLoading"
                  @click="storeEnterpriseWeChat"
                >保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="powerlimits.includes('pzxx_gzh')" label="公众号" name="pzxx_gzh">
          <div class="pane-content">
            <el-form
              class="form"
              label-width="100"
              label-suffix="："
              :model="officialAccount"
            >
              <el-form-item label="appid" prop="appid" :rules="{ required: true, message: '请输入appid' }">
                <el-input v-model="officialAccount.appid" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="appSecret" prop="appsecret" :rules="{ required: true, message: '请输入appSecret' }">
                <el-input v-model="officialAccount.appsecret" placeholder="请输入" />
              </el-form-item>
              <el-form-item label="原始id" prop="originalid" :rules="{ required: true, message: '原始id' }">
                <el-input v-model="officialAccount.originalid" placeholder="请输入" />
              </el-form-item>
              <el-form-item>
                <el-button
                  v-if="powerlimits.includes('pzxx_bc')"
                  class="submit-btn"
                  type="primary"
                  :loading="officialLoading"
                  @click="storeOfficialAccount"
                >保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script setup>
import {reactive, ref, watch} from 'vue'
import {http, tool} from '@/assets/js'
import {ElMessage} from 'element-plus'
import {useRoute} from 'vue-router'

const route = useRoute()
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.roles,'component'))

const tabs = ref('')
watch(powerlimits, (val) => {
  if(val && tabs.value === '') {
    if(powerlimits.includes('pzxx_other')) tabs.value = 'pzxx_other'
    else if(powerlimits.includes('pzxx_qywx')) tabs.value = 'pzxx_qywx'
    else if(powerlimits.includes('pzxx_gzh')) tabs.value = `pzxx_gzh`
    else tabs.value = ''
  }
}, {
  immediate: true,
  deep: true
})

const uploadMode = ref({
  uploadinfo: {
    httpRequest: true,
    limit: 5,
    rules: {
      images: {
        image_width: 0, //图片宽度，0表示不限宽
        image_height: 0, //图片高度，0表示不限高
        size: 1024*5, //文件大小（单位kb）
        type: ['image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/png'], //文件格式
        suffix: ['jpeg', 'jpg', 'gif', 'bmp', 'png'], //文件后缀-用于判断
        typechart: 'png/bmp/gif/jpg/jpeg' //文件格式提示规则
      }
    }
  }
})
const uploadAction = (e) => {
  if(e.actionData.res.status === 200) {
    enterpriseWeChat.value.img = e.actionData.url
  }
}

// 获取配置信息
const otherForm = ref({
  mobile: ''
})
const otherFormRules = ref({
  mobile: [
    { required: true, message: '请输入手机号' },
    { pattern: /^((13[0-9])|(14[5|7])|(15[0-3|5-9])|(17[0|1|3|5-8])|(18[0-9])|166|198|199)\d{8}$/, message: '手机号格式不正确', trigger: 'blur' }
  ]
})

const officialAccount = ref({
  appid: '',
  appsecret: '',
  originalid: ''
})
const enterpriseWeChat = ref({
  corp_name: '',
  corpid: '',
  yy_secret: '',
  yy_token: '',
  all_token: '',
  all_secret: '',
  encoding_aes_key: '',
  yy_encoding_aes_key: '',
  agentid: '',
  // img: '',
})
const getSettingData = () => {
  if(powerlimits.includes('pzxx_other')){
    http.post(`/admin/config/info`, {
      key_name: 'other_config'
    }).then(res => {
      otherForm.value.mobile = res.mobile
    })
  }
  // 公众号
  if(powerlimits.includes('pzxx_gzh')) {
    http.post(`/admin/config/info`, {
      key_name: 'offiaccount_config'
    }).then(res => {
      officialAccount.value = res
    })
  }
  // 企业微信
  if(powerlimits.includes('pzxx_qywx')) {
    http.get(`/admin/config/corpSave`).then(res => {
      enterpriseWeChat.value = res
    })
  }
}
getSettingData()

// 保存手机号
const otherLoading = ref(false)
const storeOther = () => {
  otherLoading.value = true
  http.post(`/admin/config/update`, {
    key_name: 'other_config',
    contents: {
      mobile: otherForm.value.mobile
    },
    remark: ''
  }).then(res => {
    ElMessage.success('操作成功')
  }).finally(() => {
    otherLoading.value = false
  })
}

// 保存企业微信
const enterpriseLoading = ref(false)
const storeEnterpriseWeChat = () => {
  enterpriseLoading.value = true
  // if(enterpriseWeChat.value.img) delete enterpriseWeChat.value.img
  http.post('/admin/config/corpSave', {
    data: [enterpriseWeChat.value]
  }).then(res => {
    if(res && typeof res === 'boolean') ElMessage.success('操作成功')
  }).finally(() => {
    enterpriseLoading.value = false
  })
}

// 保存公众号
const officialLoading = ref(false)
const storeOfficialAccount = () => {
  officialLoading.value = true
  http.post(`/admin/config/update`, {
    key_name: 'offiaccount_config',
    contents: {
      ...officialAccount.value
    },
    remark: ''
  }).then(res => {
    ElMessage.success('操作成功')
  }).finally(() => {
    officialLoading.value = false
  })
}
</script>

<style scoped lang="scss">
.setting-page{
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  h2{
    margin: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--el-border-color-light);
  }
  .setting-container{
    margin: 25px 0;
    .pane-content{
      display: flex;
      flex-direction: row;
      justify-content: center;
      .form{
        width: 60%;
        padding: 20px 0;
        .submit-btn{
          width: 140px;
        }
      }
    }
  }
  
  .upload{
    display: flex;
    flex-direction: column;
    .tips-text{
      font-size: 12px;
      color: #999;
    }
    .upload-box{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      box-sizing: border-box;
      width: 100px;
      height: 100px;
      padding-top: 10px;
      border-radius: 4px;
      border: 1px dashed #999;
      color: #999;
      background-color: #f7f8f7f8;
      &:hover{
        border-color: var(--el-color-primary);
        color: var(--el-color-primary);
      }
    }
  }
}
</style>
